<template>
  <content-not-view v-if="!subPermission.index" />
  <loading v-else-if="isLoading" />
  <div v-else class="edy-year-list">
    <b-card>
      <div class="box-btn">
        <div class="box-search">
          <b-form-group>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchHere')"
            />
          </b-form-group>
          <b-button
            v-if="subPermission.update"
            :disabled="!is_orderd"
            @click="handleSort"
            class="ml-2 sort"
            :class="{ 'sort-disabled': is_orderd }"
          >
            {{ $t("g.save_the_order") }}
          </b-button>
        </div>
        <b-link
          v-if="subPermission.store"
          class="btn btn-primary add-year"
          variant="primary"
          :to="{ name: 'AddEducationYear' }"
        >
          {{ $t("g.add_education_year") }}
        </b-link>
      </div>
      <div class="header">
        <div class="header-item">{{ $t("g.image") }}</div>
        <div class="header-item">{{ $t("g.name") }}</div>
        <div class="header-item">{{ $t("g.status") }}</div>
        <div class="header-item">{{ $t("g.date") }}</div>
        <div
          class="header-item text-center"
          v-if="subPermission.update || subPermission.destroy"
        >
          {{ $t("g.action") }}
        </div>
      </div>
      <draggable
        v-model="items"
        @end="checkOnOrders"
        v-if="subPermission.update"
      >
        <div v-for="item in filteredItems" :key="item.id" class="item-box">
          <div class="one-item">
            <div v-if="subPermission.show === false">
              <b-img :src="dash" />
              <b-img
                :src="
                  item.media && item.media.length
                    ? item.media[0].path
                    : ImagePlaceholder
                "
              />
            </div>
            <b-link
              v-else
              :to="{ name: 'ShowEducationYear', params: { id: item.id } }"
            >
              <b-img :src="dash" />
              <b-img
                :src="
                  item.media && item.media.length
                    ? item.media[0].path
                    : ImagePlaceholder
                "
              />
            </b-link>
          </div>
          <div class="one-item">
            <b-link
              v-if="subPermission.show"
              :to="{ name: 'ShowEducationYear', params: { id: item.id } }"
            >
              {{ item.name }}
            </b-link>
            <div v-else>
              {{ item.name }}
            </div>
          </div>
          <div class="one-item">
            <b-badge
              :variant="item.is_available ? 'success' : 'danger'"
              class="year-status"
            >
              {{ item.is_available ? $t("g.available") : $t("g.notAvailable") }}
              <feather-icon
                :icon="item.is_available ? 'EyeIcon' : 'EyeOffIcon'"
              />
            </b-badge>
          </div>
          <div class="one-item">{{ format(item.created_at, false) }}</div>
          <div
            class="one-item text-center"
            v-if="subPermission.update || subPermission.destroy"
          >
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                v-if="subPermission.update"
                :to="{ name: 'EditEducationYear', params: { id: item.id } }"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                <span>{{ $t("g.edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="subPermission.destroy"
                @click="deleteAlert(item.id)"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
                <span>{{ $t("g.delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </draggable>

      <div v-if="!subPermission.update">
        <div v-for="item in filteredItems" :key="item.id" class="item-box">
          <div class="one-item">
            <div v-if="subPermission.show === false">
              <b-img
                :src="
                  item.media && item.media.length
                    ? item.media[0].path
                    : ImagePlaceholder
                "
              />
            </div>
            <b-link
              v-else
              :to="{ name: 'ShowEducationYear', params: { id: item.id } }"
            >
              <b-img :src="dash" />
              <b-img
                :src="
                  item.media && item.media.length
                    ? item.media[0].path
                    : ImagePlaceholder
                "
              />
            </b-link>
          </div>
          <div class="one-item">
            <b-link
              v-if="subPermission.show"
              :to="{ name: 'ShowEducationYear', params: { id: item.id } }"
            >
              {{ item.name }}
            </b-link>
            <div v-else>
              {{ item.name }}
            </div>
          </div>
          <div class="one-item">
            <b-badge
              :variant="item.is_available ? 'success' : 'danger'"
              class="year-status"
            >
              {{ item.is_available ? $t("g.available") : $t("g.notAvailable") }}
              <feather-icon
                :icon="item.is_available ? 'EyeIcon' : 'EyeOffIcon'"
              />
            </b-badge>
          </div>
          <div class="one-item">{{ format(item.created_at, false) }}</div>
          <div
            class="one-item text-center"
            v-if="subPermission.update || subPermission.destroy"
          >
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                v-if="subPermission.update"
                :to="{ name: 'EditEducationYear', params: { id: item.id } }"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                <span>{{ $t("g.edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="subPermission.destroy"
                @click="deleteAlert(item.id)"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
                <span>{{ $t("g.delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import formatDate from "@/composables/format-date/format-date";
import draggable from "vuedraggable";
import loading from "@/components/loading/loading.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BDropdownItem,
  BDropdown,
  BLink,
  BImg,
  BBadge,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BDropdownItem,
    BDropdown,
    BLink,
    draggable,
    loading,
    BImg,
    BBadge,
    contentNotView,
  },
  data() {
    return {
      format: formatDate,
      draggedItemIndex: null,
      isLoading: true,
      items: null,
      ImagePlaceholder: require("@/assets/images/edu-year/subject.jpg"),
      searchTerm: null,
      is_orderd: false,
      dash: require("@/assets/images/edu-year/dash.png"),
    };
  },

  computed: {
    filteredItems() {
      if (this.searchTerm) {
        return this.items.filter((item) =>
          item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      } else {
        return this.items;
      }
    },
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "edu-years");
      return this.$store.state.userData.sub_Permission;
    },
  },
  created() {
    this.getEduYears();
  },
  methods: {
    checkOnOrders() {
      this.is_orderd = true;
    },
    handleSort() {
      this.isLoading = true;
      this.items.map((el, index) => {
        let formData = new FormData();
        formData.append("order", index + 1);

        this.$http
          .post(`admin/edu-years/${el.id}`, formData, {
            params: { _method: "put" },
          })
          .then((res) => {
            if (this.items.length === index + 1) {
              this.$helpers.makeToast(
                "success",
                this.$t("g.edu_year/sorted_successfully"),
                this.$t("g.edu_year/edu_year_sorted_successfully")
              );
            }
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          })
          .catch((err) => {
            console.log(err.response);
            this.$helpers.handleError(err);
          });
      });
    },
    getEduYears() {
      this.$http
        .get("admin/edu-years")
        .then((res) => {
          this.items = res.data.data;
          setTimeout(() => {
            this.isLoading = false;
          }, 300);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          this.isLoading = false;
        });
    },
    deleteAlert(id) {
      this.$helpers.sweetAlertConfirm(
        this.$t("g.are_you_sure"),
        this.$t("g.you_wont_be_able_to_revert_this"),
        "warning",
        this.$t("g.yes_delete_it"),
        this.$t("g.cancel"),
        () => this.deleteEduYear(id)
      );
    },
    deleteEduYear(id) {
      this.$http
        .delete(`admin/edu-years/${id}`)
        .then((res) => {
          this.getEduYears();
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
